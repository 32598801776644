import React from 'react'
import styled from 'styled-components'

const BookSummaryComponent = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
    display: grid;
    grid-template-rows: 0.2fr 50px 110px 110px 150px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "sg_title sg_title"
    "sg_origin sg_origin_details"
    "sg_destination sg_destination"
    "sg_shipment_items sg_shipment_items"
    "sg_disclaimer sg_disclaimer";
`;

const SgTitle = styled.p`
  grid-area: sg_title;

  border-bottom: 1px solid #eee;
  font-family: 'Teko', sans-serif !important;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  font-size: 30px;
  color: #141b1f;
`;

const SgOrigin = styled.div`
  grid-area: sg_origin;
  align-self: end;
  font-family: "Open Sans";
  span {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  }
`;
const SgOriginDetails = styled.div`
  grid-area: sg_origin_details;
  font-family: "Open Sans";
  align-self: end;
`;

const SgDestination = styled.p`
  grid-area: sg_destination;
  font-family: "Open Sans";

  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  color: #141b1f;
  font-weight: 400;
  font-size: 15px;
  align-self: end;
  span {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  }
`;

const SgShipmentItems = styled.p`
  grid-area: sg_shipment_items;
  font-family: "Open Sans";
padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  color: #141b1f;
  font-weight: 400;
  font-size: 15px;
  align-self: end;
  span {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  }
`;

const Disclaimer = styled.div`
  grid-area: sg_disclaimer;
  font-family: "Open Sans";
  font-size: 10px;
  color: #a9adaf;
  border-bottom: 1px solid #eee;
`;


const EchoShipOrganismComponent = () => {
  return (

    <BookSummaryComponent>

      <SgTitle>Summary</SgTitle>
      <SgOrigin>       
      <span>Origin</span>  <br />
        Chicago, IL<br />
        60610 US

      </SgOrigin>
      <SgOriginDetails>
        Tue, Apr 16<br />
        11:30 AM - 5:00 PM
        </SgOriginDetails>
      <SgDestination>
      <span>Destination</span>  <br />
        Long Beach, CA<br />
        90802

      </SgDestination>
      <SgShipmentItems>
        <span>Shipment Items (1)</span> <br />
        0301 qa test1<br />
        500 LBS<br />
        125 Freight Class
      </SgShipmentItems>

      <Disclaimer>
        These are estimated transit times and rates based on business days for Standard LTL shipments and are not guaranteed. Inside delivery, liftgate, holiday, appointment scheduling, indirect shipments and other factors may add additional time for transit. Please contact an Echo Sales Representative for all of your specific pricing and scheduling needs, including guaranteed and expedited shipments.
      </Disclaimer>


    </BookSummaryComponent>

  )
}

export default EchoShipOrganismComponent;