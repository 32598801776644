import React from "react";
import { Modal } from "semantic-ui-react";
import '../../styles/modal.css';
import '../../styles/icons/css/icons.css'
import styled from "styled-components";
import { Link } from "gatsby"

import PrimaryButton from '../../components/Buttons/PrimaryButton.js'

// 💅
const ModalComponentContinue = styled.div`
  /* border-radius: 4px;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5); */
  max-width: 960px;
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
`;

const ModalGrid = styled.div`
  max-width: 960px;
  // 📰
  display: grid;
  grid-template-rows: 0.2fr 1fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "g_modal_header"
    "g_modal_content"
    "g_modal_footer";
`;

// MODAL GRID: HEADER
const ModalHeader = styled.div`
  grid-area: g_modal_header; // 🗺
  min-height: 85px;
  padding-top: 0;
  margin-bottom: 20px;

  border-bottom: 2px solid #e7edf0;
  margin-left: 15px;
  margin-right: 15px;

  .ModalTitle {
    font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    text-align: center;
    padding-top: 20px;
  }
  .ui.page.modals.dimmer.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal {
    margin: 0 auto !important;
  }
`;

// MODAL GRID: CONTENT
const ModalGridContent = styled.div`
  font-family: "Open Sans";
  grid-area: g_modal_content; // 🗺
  padding-left: 40px;
  padding-right: 40px;

  p {
    font-size: 15px;
line-height: 20px;
text-align: center;
letter-spacing: -0.321429px;

color: #000000;
  }
`;

const Options = styled.div`
  margin-bottom: 10px;
  display: grid;
  padding-left: 150px;
.OptionGrid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0.2fr 0.8fr;
  grid-template-areas:
    "g_circle g_text";
  background: #F7F7F7;
  border: 1px solid #979797;
  border-radius: 3px;
  width: 325px;
  height: 59px;
}
.Circle {
  grid-area: g_circle; 
  align-self: center;
  justify-self: end;
  padding-right: 20px;
  color: #666666;
}
.Text {
  grid-area: g_text; 
  align-self: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;

  color: #666666;
}
`;

const ModalContinue = (props) => (
  <Modal trigger={<PrimaryButton orange>Select</PrimaryButton>}>

      <ModalComponentContinue>
      
        <ModalGrid>
          <ModalHeader>
            <div className="ModalTitle">Confirmation</div>
            <div className="Exit" />
          </ModalHeader>

          <ModalGridContent>
            <p>Your selected carrier, UPS FREIGHT, only covers $4,000.00 of your $10,000.00 shipment. <br />
            How would you like to proceed?</p>
            <Options>
              <div className="OptionGrid">
                <div className="Circle">
                  <i class="far fa-circle"></i>
                </div>
                <div className="Text">
                  Insure this shipment's full value with Echo for <b>$22.73</b>
                </div>
              </div>
            </Options>

            <Options>
              <div className="OptionGrid">
                <div className="Circle">
                  <i class="far fa-circle"></i>
                </div>
                <div className="Text">
                  Continue with carrier's liability coverage
                </div>

              </div>
            </Options>

        </ModalGridContent>


        <div style={{paddingTop: '20px', margin: '20px 15px 0 15px', textAlign: 'center', borderTop: '2px solid #e7edf0'}}>
          <Link to="/BookShipment/ReviewAndDispatch/">
            <button className="eKLoPe">CONTINUE</button>
          </Link>
        </div>


        </ModalGrid>
      </ModalComponentContinue>

  </Modal>
);
export default ModalContinue;