import React from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css';

import ExpandRateResult from '../../components/Misc/ExpandRateResult.js'
import ModalComponentContinue from '../../FunctionalComponents/Modal/ModalContinue.js'

const CarrierResultsAccordionLinkTwo = styled.div`
  .es-c-accordion--carrier {
    // 🎛
    // 🎨
    border-top: 0.5px solid #bbbbbb;
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
      background-color: #f7f7f7;
    }
    // 🖋
    font-family: "Open Sans";
    color: #0033a1;
    // 📰
    display: grid;
    grid-template-rows: 20px 60px 0.2fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "t_title t_title t_title t_title c_carrier_price"
      "c_img t_transit_time t_est_delivery t_cargo_liability c_carrier_price"
      "c_more_accordion c_more_accordion c_more_accordion c_more_accordion c_more_accordion";

    .es-c-carrier--img {
      grid-area: c_img; // 🗺
      vertical-align: middle;
      .carrier-thumbnail {
        width: 60px !important;
      }
      img {
        left: 10px;
      }
    }
    .es-t-carrier--transit-time {
      grid-area: t_transit_time; // 🗺
      justify-self: center;
      .es-t-days {
        font-weight: 400;
        color: #141b1f;
        margin-bottom: 0;
        font-size: 18px;
        margin-top: 4px;
      }
      .es-t-direct {
        color: #777;
        font-size: 12px;
        white-space: nowrap;
        font-style: italic;
      }
    }
    .es-t-carrier--est-delivery {
      grid-area: t_est_delivery; // 🗺
      justify-self: center;
      .es-t-date {
        font-weight: 400;
        color: #141b1f;
        margin-bottom: 0;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .es-t-carrier--cargo-liability {
      grid-area: t_cargo_liability; // 🗺
      justify-self: center;
      p {
        color: #666;
        margin: 0px;
        margin-top: 4px;
        span {
          color: #666;
          font-weight: 400;
          color: #141b1f;
          margin-bottom: 0;
          margin-top: 4px;
        }
      }
    }
    .es-c-more-accordion {
      grid-area: c_more_accordion; // 🗺
      color: #0033a1;
      cursor: pointer;
      font-size: 12px;
    }
    .es-c-carrier--price {
      padding-top: 15px;
      grid-area: c_carrier_price; // 🗺
      justify-self: center;
      p {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: #2b363b;
        margin: 0px;
      }
    }
    .es-t-label {
      font-size: 12px;
      letter-spacing: 0.3px;
      color: #666;
      font-weight: 600;
      margin: 0px;
      p {
        margin-top: 4px;
      }
    }
    .fas.fa-chevron-circle-down {
      margin-right: 3px;
      padding-left: 5px;
    }
  }
`

const AccordionTitle = styled.div `
    grid-area: t_title; // 🗺
    font-size: 12px;
    text-transform: uppercase;
    color: #2b363b;
    padding-bottom: 6px;
    padding-left: 10px;
`;


const AccordionComponent = ({children}) => {
  return (
    <CarrierResultsAccordionLinkTwo>
      <div className="es-c-accordion--carrier">
        <AccordionTitle>UPS FREIGHT</AccordionTitle>
      
        <div className="es-c-carrier--image">
          <img
            className="carrier-thumbnail"
            src="https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337662/ups-freight.png" 
          />
        </div>
        <div className="es-t-carrier--transit-time">
          <p className="es-t-label">Transit Time</p>
          <p className="es-t-days">4 Days</p>
          <p className="es-t-direct">Direct</p>
        </div>
        <div className="es-t-carrier--est-delivery">
          <p className="es-t-label">Estimated Delivery</p>
          <p className="es-t-date">Fri, May 3</p>
        </div>
        <div className="es-t-carrier--cargo-liability">
          <div className="es-t-label">Cargo Liability</div>
          <p>
            <span>$4,000.00</span> New
          </p>
          <p>
            <span>$50.00</span> Used
          </p>
        </div>
        <div className="es-c-carrier--price">
          <p>$227.24</p>
          <ModalComponentContinue>
            {children}
          </ModalComponentContinue>
        </div>
        <div className="es-c-more-accordion">
          <div className="accordion">
            <input
              type="checkbox"
              id="accordion-1"
              name="accordion-checkbox"
              hidden
            />
            <label className="accordion-header" htmlFor="accordion-1" >
              <i class="fas fa-chevron-circle-down"></i>
                More Info
            </label>
            <div className="accordion-body">
              <ExpandRateResult></ExpandRateResult>
            </div>
          </div>
        </div>
      </div>
    </CarrierResultsAccordionLinkTwo>
  )
}

export default AccordionComponent